/* General + Third-Party */
import jQuery from 'jquery';
import { lazyload } from './globals/lazyload';


/* Layout-Parts */
import { toggleHamburger } from './layout/navigation';
import { navCloserOnClick } from './layout/navigation';

/* Blocks */
import {sliderStripesChanger } from '../../blocks/slider-stripes/script';
import {sliderSimple} from '../../blocks/slider-simple/script';
import { galleryIsotopeSorter } from "../../blocks/gallery-sort/script";
import { galleryCategoryToggle } from "../../blocks/gallery-sort/script";
import { galleryFancyBoxCaption } from "../../blocks/gallery-sort/script";

jQuery(document).ready(_ => {
    lazyload();
    sliderStripesChanger();
    toggleHamburger();
    navCloserOnClick();
    sliderSimple();
    // galleryIsotopeSorter();
    // galleryCategoryToggle();
    galleryFancyBoxCaption();

});
