import "owl.carousel";
function sliderSimple() {
  let owlCardsSlider = jQuery(".slider-simple-wrapper");

  owlCardsSlider.owlCarousel({
    nav: true,
    navText: [
      '<span class="owl-carousel-pfeil"><img src="/wp-content/themes/bergauf/blocks/slider-simple/icons/slider-simple-icon-left.png" alt="Immoore Landtechnisch -  Slider Icon"/></span>',
      '<span class="owl-carousel-pfeil"><img src="/wp-content/themes/bergauf/blocks/slider-simple/icons/slider-simple-icon-right.png" alt="Immoore Landtechnisch -  Slider Icon"/></span>',
    ],
    mouseDrag: false,
    touchDrag: false,
    loop: true,
    dots: true,
    margin: 10,
    autoplay: false,
    smartSpeed: 1000,
    items: 1,
    lazyLoad: true,
    responsive: {
      0: {
        nav: true,
        dots: false,
        autoHeight: true
      },
      768: {
        nav: false,
        dots: true,
        autoHeight: false
      }
    },
  });


  $(window).on('resize', function(){
    owlCardsSlider.trigger('refresh.owl.carousel');
    console.log('refresh');
  });
}
export { sliderSimple };
