function sliderStripesChanger() {

  const sliderStripes         = document.getElementsByClassName('slider-stripes-item');
  const sliderStripesLength   = sliderStripes.length

  $("#slider-stripe-1").addClass("open");

  for (let i = 1; i <= sliderStripesLength; i++) {
    $("#slider-stripe-"+i).on("click", function () {
      $(".slider-stripes-item").removeClass("open")
      $(this).addClass("open");
    });
  }
}
export { sliderStripesChanger };
