function toggleNavigation() {
    jQuery('#nav-burger').click(function (event) {
        event.preventDefault();
        jQuery(this).add('html, #nav-container').toggleClass('open-nav');
    })

    jQuery('#main-nav ul li span').click(function (event) {
        event.preventDefault();
        jQuery(this).toggleClass('open');
        jQuery(this).next().toggleClass('open');
    })
}
export { toggleNavigation };

function toggleHamburger() {
    $("#nav-toggler-wrapper").click(function () {
      $(".hamburger").toggleClass("is-active");
      $(".nav-container").toggleClass("nav-container_open");
      $("body").toggleClass("body_overlay");
      return false;
    });
  }
  export { toggleHamburger };




  function navCloserOnClick() {
    $(document).click(function () {
      if ($("body").hasClass("body_overlay")) {
        $("#nav-toggler-wrapper").click();

      }
    });
}

export { navCloserOnClick };
